<template>
  <div class="search">
    <div class="flex_start flex_items">
      <van-search
        v-model="value"
        shape="round"
        background="#fff"
        placeholder="Search"
        style="width: 82vw"
        type="serch"
        @keypress="searchGoods"
      />
      <span class="c0 f14 bold">Cancle</span>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      value: "",
    };
  },
  mounted() {
    document.body.style.backgroundColor = "#ffffff";
  },
  methods: {
    searchGoods(event) {
      if (event.keyCode == 13) {
        event.preventDefault(); //禁止默认事件（默认是换行）
        this.getData(this.value)
      }
    },
    getData(){
        
    }
  },
};
</script>
<style>
.search {
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>